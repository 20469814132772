<template>
  <div data-app>
      <v-card>
        <v-card-title>
          <h3>{{ cardTitle }}</h3>   
        </v-card-title>
        <v-card-text>
          
          <FileUploadModalForm
              :fileUploadModalData="fileUploadModalData"
              @closeFileUploadModalForm="handleCloseFileUploadModalForm"
              @setErrorReports="handleSetErrorReports"
          ></FileUploadModalForm>
            
            <h5 v-if="errorReports" style="color:red; padding-left: 2px;">Az importálás során az alábbi hibák keletkeztek:</h5>  

          <v-simple-table v-if="errorReports" fixed-header dense>
            <template v-slot:default>
              <thead>
                <tr>
                    <th class="text-left"></th>
                    <th class="text-left"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in errorReports" :key="item.index">           
                    <td v-if="item.row" style="color:red;"><span style="font-weight:bold;">{{ item.row }}.</span> sor</td>
                  <td v-if="item.column" style="color:red;"><span style="font-weight:bold;">{{ item.column }}.</span> oszlop</td>
                  <td>{{ item.description }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>    
  </div>
</template>

<script>

import FileUploadModalForm from "./FileUploadModalForm";

export const PERMISSION_TO = "products.product.";

export default {
  name: "ImportProducts",
  components: {FileUploadModalForm },
  data() {
    return {
      cardTitle: this.$t("MENU.IMPORT_PRODUCTS"),
      search: "",
      routePath: "/products/products/",
      permissionTo: PERMISSION_TO,
      permissions: JSON.parse(localStorage.permissions),

      errorReports: null,


      fileUploadModalData: {
          dialog: false,
      },
    };
  },

  computed: {},

  methods: {
    handleCloseFileUploadModalForm() {
      this.fileUploadModalData.dialog = false;
    },
    handleSetErrorReports(errorReports) {
      this.errorReports = errorReports;
    }
  },

  mounted() {},
};
</script>
