<template>
  <div class="text-center">
    <v-dialog v-model="fileUploadModalData.dialog" width="500" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" class="ml-3 mb-2" dark v-bind="attrs" v-on="on">
          {{ $t("MEDIA_LIBRARY.file_upload") }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <span class="headline"
                >{{ $t("MEDIA_LIBRARY.file_upload") }}
              </span>
              <v-btn
                @click="handleCloseFileUploadModalForm"
                icon
                class="close-button"
              >
                <v-icon> mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-subtitle
          >{{ $t("MEDIA_LIBRARY.file_upload_max_size") }}:
          {{ this.bytesToSize(config.maxFileSize) }}<br />
          Feltölthető fájltípus: *.xlsx
        </v-card-subtitle>

        <v-card-text>
          <v-form
            ref="fileUploadForm"
            v-model="fileUploadFormValid"
            lazy-validatio
          >
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="12" md="12">
                <v-file-input
                  small-chips
                  multiple
                  show-size
                  counter
                  :accept="config.accept"
                  v-model="files"
                  :label="$t('MEDIA_LIBRARY.please_select')"
                  :rules="[
                    (value) => {
                      return ruleMaxFiles(value);
                    },
                  ]"
                >
                </v-file-input>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="handleCloseFileUploadModalForm">
            {{ $t("FORMS.cancel") }}
          </v-btn>
          <v-btn color="primary" @click="handleFileUploadModalForm">
            {{ $t("MEDIA_LIBRARY.uploading") }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-overlay :value="loader">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
import SiteService from "@/core/services/site.service.js";
import { mediaLibraryMixins } from "@/view/mixins/mediaLibraryMixins.js";
export default {
  name: "FileUploadModalForm",
  props: ["fileUploadModalData"],
  mixins: [mediaLibraryMixins],
  data: () => ({
    loader: false,
    files: [],
    config: {
      maxFileSize: 33554432,
      maxFilesCount: 1,
      accept:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
  }),
  computed: {
    endPoint() {
      return "products/admin/" + SiteService.getActiveSiteId() + "/import-product";
    },
  },
  watch: {
    fileUploadModalData: {
      deep: true,
      handler() {
        //this.files = Object.assign({}, initialFormData());
        this.files = [];
      },
    },
  },
  methods: {
    handleCloseFileUploadModalForm() {
      this.$emit("closeFileUploadModalForm");
    },
    handleFileUploadModalForm() {
      this.$refs.fileUploadForm.validate();
      if (this.fileUploadFormValid) {
        this.$emit("setErrorReports", null);
        const formData = new FormData();
        for (let file of this.files) {
          formData.append("files[]", file, file.name);
        }
        this.loader = true;
        axios
          .post(this.endPoint, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response);
            Swal.fire({
              title: "",
              text: "A temékek importálása sikeresen megtörtént.",
              icon: "success",
              confirmButtonClass: "btn btn-secondary"
            });
            this.$emit("closeFileUploadModalForm");
            this.loader = false;
          })
          .catch((errors) => {
            console.log(errors.response);
            if(errors.response.status == 422) {
              /*Swal.fire({
                title: "",
                text: errors.response.data.errors,
                icon: "error",
                confirmButtonClass: "btn btn-secondary"
              });*/
            }
            else {
              console.log(errors);
            }
            this.$emit("setErrorReports", errors.response.data.errors);
            this.$emit("closeFileUploadModalForm");
            this.loader = false;
            this.loader = false;
          });
      }
    },
    ruleMaxFiles(value) {
      if (
        !!value &&
        value.some((file) => file.size > this.config.maxFileSize)
      ) {
        return (
          this.$t("MEDIA_LIBRARY.file_upload_max_size") +
          ": " +
          this.bytesToSize(this.config.maxFileSize)
        );
      }

      if (!!value && value.length > this.config.maxFilesCount)
        return (
          this.$t("MEDIA_LIBRARY.file_upload_max_count") +
          ": " +
          this.config.maxFilesCount
        );
      return true;
    },
  },
  creaated() {},
};
</script>
